(function ($) {
  var init_header = function() {
    var largeScreen = matchMedia('only screen and (min-width: 786px)').matches;
    if( largeScreen ) {
      if( $().sticky ) {
        $('.header-sticky').sticky();
      }
    }
  };

var ResponsiveMenu =  {
        menuType: 'desktop',
        initial: function(winWidth) {
            ResponsiveMenu.menuWidthDetect(winWidth);
            ResponsiveMenu.menuBtnClick();
            ResponsiveMenu.parentMenuClick();
        },
        menuWidthDetect: function(winWidth) {
            var currMenuType = 'desktop';
            if (matchMedia('only screen and (max-width: 991px)').matches) {
                currMenuType = 'mobile';
            }
            if ( currMenuType !== ResponsiveMenu.menuType ) {
                ResponsiveMenu.menuType = currMenuType;
                if ( currMenuType === 'mobile' ) {
                    $('.mainnav li.mega a').after($('.mega-wrap ul.sub-menu'));
                    var $mobileMenu = $('#mainnav').attr('id', 'mainnav-mobi').hide();
                    $('.header-wrap').find('.header-nav').after($mobileMenu);

                    var hasChildMenu = $('#mainnav-mobi').find('li:has(ul)');
                    hasChildMenu.children('ul').hide();
                    hasChildMenu.children('a').after('<span class="btn-submenu"></span>');
                    $('.btn-menu').removeClass('active');
                } else {
                    $('.mega-wrap .mega-menu').append($('.mainnav li.mega ul.sub-menu').show());
                    var $desktopMenu = $('#mainnav-mobi').attr('id', 'mainnav').removeAttr('style');
                    $desktopMenu.find('.sub-menu').removeAttr('style');
                    $('.header-wrap').find('.btn-menu').after($desktopMenu);
                    $('.btn-submenu').remove();
                }
            } // clone and insert menu
        },
        menuBtnClick: function() {
            $('.btn-menu').on('click', function() {
                $('#mainnav-mobi').slideToggle(300);
                $(this).toggleClass('active');
            });
        }, // click on moblie button
        parentMenuClick: function() {
            $(document).on('click', '#mainnav-mobi li .btn-submenu', function(e) {
                if ( $(this).has('ul') ) {
                    e.stopImmediatePropagation()
                    $(this).next('ul').slideToggle(300);
                    $(this).toggleClass('active');
                }
            });
        } // click on sub-menu button
    };

  var setupMegaMenu = function() {
    $('#mainnav > ul > li.mega a').hover( function() {
      $('.mega-wrap').fadeIn('300');
    },function(){
      setTimeout(function(){ 
        if ($('.mega-wrap:hover').length == 0) {
            $('.mega-wrap').fadeOut('300');
        } 
      }, 50);
    });
    $('.mega-wrap').mouseleave(function(event) {
      event.preventDefault();
    },function(event) {
      $('.mega-wrap').fadeOut('300');
    });
  }

function mansory_recent_work(){
  if($('#iw-isotope-main').length) {
    var $container = $('#iw-isotope-main').isotope({
        itemSelector: '.element-item'
    });
    $container.imagesLoaded().progress( function() {
        $container.isotope('layout');
    });

    $('#filters button').click(function () {
        var selector = $(this).attr('data-filter');
        $('#filters button').removeClass('is-checked');
        $(this).addClass('is-checked');
        $container.isotope({filter: selector});
        return false;
    });
  }    
}

function close_navbar(){
    $('#close-navbar').click(function(){
        $('.navbar-collapse').removeClass('show');
    });
}


function min_height_banner(){
    var min_height = $('.min-height-banner').height();
    $('.no-photo .row').css({
        'min-height': min_height
    })
}

//home 006
function min_height_banner_006(){
    var min_height = $('#slider-banner-006').height();
    $('.no-photo .row').css({
        'min-height': min_height
    })
}


//01_018
function slick_banner_18(){
    var slider18 = $('.description-banner');
    slider18.slick({
        arrows: false,
        autoplay: false,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        speed: 500,
        fade: true,
        cssEase: 'linear'
    });
}
function slick_banner_21() {
  if ($('.slider21').length >0){
    $('.slider21').slick({
      centerMode: true,
      slidesToShow: 3,
      //autoplay: true,
      autoplaySpeed: 2000,
      dots: false,
      variableWidth: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: true,
            slidesToShow: 3
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerMode: true,
            slidesToShow: 1
          }
        }
      ]
    });
  }    
}


function slider_services_006(){
    var slider = $('#slider-services-006');
    slider.slick({
        arrows: true,
        autoplay: false,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 567,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    });
}

var googleMap = function() {
  if ( $().gmap3 ) {
    var _draggable = true;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        _draggable = false;
    }
      $('#gmap').gmap3({
          map:{
              options:{
                  zoom: 14,
                  mapTypeId: 'consultant_style',
                  styles: [{
                    "featureType": "landscape",
                    "stylers": [{
                      "saturation": -100
                    }, {
                      "lightness": 65
                    }, {
                      "visibility": "on"
                    }]
                  }, {
                    "featureType": "poi",
                    "stylers": [{
                      "saturation": -100
                    }, {
                      "lightness": 51
                    }, {
                      "visibility": "off"
                    }]
                  }, {
                    "featureType": "road.highway",
                    "stylers": [{
                      "saturation": -100
                    }, {
                      "visibility": "simplified"
                    }]
                  }, {
                    "featureType": "road.arterial",
                    "stylers": [{
                      "saturation": -100
                    }, {
                      "lightness": 30
                    }, {
                      "visibility": "on"
                    }]
                  }, {
                    "featureType": "road.local",
                    "stylers": [{
                      "saturation": -100
                    }, {
                      "lightness": 40
                    }, {
                      "visibility": "on"
                    }]
                  }, {
                    "featureType": "transit",
                    "stylers": [{
                      "saturation": -100
                    }, {
                      "visibility": "simplified"
                    }]
                  }, {
                    "featureType": "administrative.province",
                    "stylers": [{
                      "visibility": "off"
                    }]
                  }, {
                    "featureType": "water",
                    "elementType": "labels",
                    "stylers": [{
                      "visibility": "on"
                    }, {
                      "lightness": -25
                    }, {
                      "saturation": -100
                    }]
                  }, {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [{
                      "hue": "#ffff00"
                    }, {
                      "lightness": -25
                    }, {
                      "saturation": -97
                    }]
                  }],
                  mapTypeControlOptions: {
                      mapTypeIds: ['consultant_style', google.maps.MapTypeId.SATELLITE, google.maps.MapTypeId.HYBRID]
                  },
                  scrollwheel: false,
                  draggable: _draggable
              }
          },
          getlatlng:{
              address:  '24 - Le Duan - Dong Da - Ha Noi',
              callback: function(results) {
                  if ( !results ) return;
                  $(this).gmap3('get').setCenter(new google.maps.LatLng(results[0].geometry.location.lat(), results[0].geometry.location.lng()));
                  $(this).gmap3({
                      marker:{
                          latLng:results[0].geometry.location,
                          options:{
                            icon: 'images/marker.png'
                          }
                      }
                  });
              }
          },
          styledmaptype:{
              id: 'consultant_style',
              options:{
                  name: 'Consultant Map'
              },
          },
      });
  }
};



//menu push
var menupush = function() {
    if ($('#cbp-spmenu-s1').length >0){
        var menuLeft = document.getElementById( 'cbp-spmenu-s1' ),
        showLeftPush = document.getElementById( 'showmenu' ),
        body = document.body;
        showLeftPush.onclick = function() {
            classie.toggle( this, 'active' );
            classie.toggle( body, 'cbp-spmenu-push-toright' );
            classie.toggle( menuLeft, 'cbp-spmenu-open' );
        };
    }
};

//portfolio
function initFixedContent() {
    $('.elvo-single-portfolio').each(function() {
        var $gallery = $(this).find('.elvo-single-gallery'),
            $content = $(this).find('.elvo-portfolio-content'),
            galleryHeight = $gallery.outerHeight() - 30,
            contentHeight = $content.outerHeight() + 30;
        if($content.length > 0 && contentHeight < galleryHeight) {
            var navHeight = $('.fixed-top').height();
            $content.stick_in_parent({offset_top: navHeight});
        }
    }); 
}

//slider thumb
function galleryThumbNav() {
  if ($(".slider-for").length > 0) {
    $('.slider-for').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: '.slider-nav'
    });
    $('.slider-nav').slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      asNavFor: '.slider-for',
      dots: false,
      arrows: false,
      focusOnSelect: true,
      responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
    });
  }    
}

function carouselSlider(){
  if ($(".carousel-slider").length > 0) {
    var carouselslider = $('.carousel-slider');
    carouselslider.slick({
        arrows: false,
        autoplay: false,
        autoplaySpeed: 600,
        centerMode: true,
        centerPadding: '50',
        dots: false,
        slidesToShow: 1,     
        variableWidth: true,
        nextArrow: $("#next"),
        prevArrow: $("#prev"),        
        // prevArrow: '<a href="javascript:void(0);" class="slick-arrow slick-prev"><i class="fa fa-angle-left"></i></a>',
        // nextArrow: '<a href="javascript:void(0);" class="slick-arrow slick-next"><i class="fa fa-angle-right"></i></a>',
        responsive: [
        {
          breakpoint: 991,
          settings: {
            centerMode: false
          }
        }
      ]
    });
  }    
}

function navbar(){
  $('.nav-main li a').click(function(){
    var subCur = $(this).attr('data-href');
    console.log(subCur);
    $('#close-navbar').hide();
    $('.nav-main').hide();
    $('.sub-menu').removeClass('active');
    $(subCur).addClass('active');
    $('.back-nav').show();
    $('.navbar-collapse-008').addClass('show-sub');
  });

  $('.back-nav').click(function(){
    $('#close-navbar').show();
    $('.nav-main').show();
    $('.sub-menu').removeClass('active');
    $(this).hide();
    $('.navbar-collapse-008').removeClass('show-sub');
  });
}

//Menu Fixed
if ($("body.sticky").length > 0) {
  var fixedSection = $('#header-logo');
  // sticky nav
  var headerHeight = fixedSection.outerHeight();
  var stickyNavTop = fixedSection.offset().top;
  fixedSection.addClass('animated');
  // fixedSection.before('<div class="nav-placeholder"></div>');
  $('.nav-placeholder').height('inherit');
  //add class
  fixedSection.addClass('menu-fixed-out');
  var stickyNav = function () {
      var scrollTop = $(window).scrollTop();
      if (scrollTop > stickyNavTop) {
          fixedSection.removeClass('menu-fixed-out').addClass('menu-fixed');
          $('.nav-placeholder').height(headerHeight);
      } else {
          if (fixedSection.hasClass('menu-fixed')) {
              fixedSection.removeClass('menu-fixed').addClass('menu-fixed-out');
              $('.nav-placeholder').height('inherit');
          }
      }
  };
  stickyNav();
  $(window).scroll(function () {
      stickyNav();
  });
};

// var slickHeightBanner = function() {
//   var slickHeightItem = $('.banner .slick-track').height();
//   $('.banner .banner-item').css('height',slickHeightItem + 'px' );
// };
function stretchFeedbackSlider() { 

    var $slider = $("#slider-banner");
    var oldWidth = $slider.width();

    stretchingSlides(); 
    $slider.on('breakpoint', stretchingSlides)
    $slider.on('setPosition', function(){
        var newWidth = $slider.width();
        if (oldWidth !== newWidth) {
            stretchingSlides();
            oldWidth = newWidth;
        }
    });

    function stretchingSlides() {
    var $slides = $slider.find(".banner-item");
        var heightSlides = [];
        var maxHeight;

        $slides.css('height', 'auto');    
        $slides.each(function(){            
            heightSlides.push($(this).height())
        })
        maxHeight  = Math.max.apply(null, heightSlides);
        
        $slides.height(maxHeight);
    }
}

$(window).ready(function(){
  $('.opencanvas').click(function(){
    $('body').addClass('body-opencanvas');
  });

  $('.close-offcanvas').click(function(){
    $('body').removeClass('body-opencanvas');
  });

    stretchFeedbackSlider();

    init_header();
    ResponsiveMenu.initial($(window).width());
    $(window).resize(function() {
      ResponsiveMenu.menuWidthDetect($(this).width());
    });
    setupMegaMenu();
    mansory_recent_work();
    close_navbar();
    min_height_banner();

    navbar();
 
    //call fuction home 008
    if($('.dotdotdot').length) {      
      $('.dotdotdot').dotdotdot();
    }

    $(window).scroll(function(){
        if ($(this).scrollTop()>100){
            $('.fixed-top').addClass('fixed-header');
        }
        else{
            $('.fixed-top').removeClass('fixed-header');
        }
    });

    $(window).resize(function(){
        min_height_banner();
        min_height_banner_006();
    });




    googleMap();
    // typed();
        
    if($('.elvo-showcase-images').length) {

      var elvoShowcase = $('.elvo-showcase-images');

      var auto_play = Boolean(elvoShowcase.attr('data-autoplay') === 'false' ? false : true);
      var data_wrap = Boolean(elvoShowcase.attr('data-wrap') === 'false' ? false : true);
      // var data_dots = Boolean(elvoShowcase.attr('data-dots') === 'false' ? false : true);

      $('.elvo-showcase-images').flickity({
          cellAlign: 'center',
          contain: false,
          wrapAround: data_wrap,
          // autoPlay: auto_play,
          //pageDots: data_dots,
          prevNextButtons: false,
          pageDots: false
      });

      var galleryCellWidth = $('.carousel-cell').width();
      var galleryCellHeight = $('.carousel-cell img').height();

      $('.elvo-showcase-frame').css({
          'width': galleryCellWidth + 30
      })
      $('.elvo-showcase-frame').css({
          'height': galleryCellHeight + 130
      })
    }

    $('.navbar-toggler').click(function(){
        $('.header-tranparents').toggleClass('open-submenu');
    });

    if ($('#typed').length >0){
      var  typed = new Typed('#typed', {
        strings: ['I’m Web Designer', 'I’m Web Photographer', 'I’m Web Professional'],
        typeSpeed: 100,
        backDelay: 3000,
        //backSpeed: 0,
        //fadeOut: true,
        loop: true
      });
    }

    //masonry
    $( window ).load( function(){
      if($('.grid').length) {
        $( '.grid' ).masonry( {
            itemSelector: '.grid-item',
            columnWidth: '.grid-sizer',
            percentPosition: true
        } );
      }        
    });

    //
    menupush();
    initFixedContent();
    galleryThumbNav();

    if ($('.slider-banner').length >0){
      $('.slider-banner').slick();
    }
    if ($('.slider-testimonials').length >0){
      $('.slider-testimonials').slick();
    }
    if ($('.slider-partners').length >0){
      $('.slider-partners').slick();
    }
    if ($('.slider-teams').length >0){
      $('.slider-teams').slick();
    }
    if ($('.slider-gallery').length >0){
      $('.slider-gallery').slick();
    }

    carouselSlider();
    slick_banner_21();

    slider_services_006();
    

});
})(jQuery);